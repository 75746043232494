import request from '@/utils/request'

/**
 * 查询店铺是否签署多多进宝协议接口
 */
export const isDDJB = data => {
  return request({
    url: '/pdd/mall/get-protocol-status',
    method: 'POST',
    data
  })
}

/**
 * 店铺信息接口
 */
export const getStoreInfo = data => {
  return request({
    url: '/pdd/mall/get-info',
    method: 'POST',
    data
  })
}

/**
 * 判断是否对商家展示某个通知
 */
export const checkNotifyType = data => {
  return request({
    url: '/pdd/mall/check-notify-type-show',
    method: 'POST',
    data
  })
}

/**
 * 溯源服务商上传溯源码信息
 */
export const TraceabilityInfo = data => {
  return request({
    url: '/pdd/mall/source-upload-code-info',
    method: 'POST',
    data
  })
}

/**
 * 溯源服务商上传正品溯源粘贴计划
 */
export const TraceabilityPlan = data => {
  return request({
    url: '/pdd/mall/source-upload-plan-info',
    method: 'POST',
    data
  })
}

/**
 * 店铺分页列表
 */
export const getMallPageList = params => {
  return request({
    url: '/mall/page-list',
    params
  })
}
/**
 * 店铺列表
 */
export const getMallList = params => {
  return request({
    url: '/mall/list',
    params
  })
}

/**
 * 店铺每日销售数据分页列表
 */
export const getDateSalesList = params => {
  return request({
    url: '/mall/page-date-salas-list',
    params
  })
}
