import request from '@/utils/request'

/**
 * 获取授权code请求url
 */
export const getCodeUrl = () => {
  return request({
    url: '/pdd/code-url',
    params: {
      provider: 1
    }
  })
}

/**
 * 获取接口access token
 */
export const postAccessToken = data => {
  return request({
    url: '/pdd/access-token',
    method: 'POST',
    data
  })
}
