<template>
  <div class="home">

    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">店铺数据</div>
        <div class="card-hd-right">
          <Refresh @refreshCall="refreshHandle(1)"></Refresh>
        </div>
      </div>
      <div class="card-bd">
        <div class="card-bd-alone">
          <div class="card-bd-alone-item" v-for="(item, index) in aloneList" :key="index">
            <div class="card-bd-alone-item-title">{{item.title}}</div>
            <div class="card-bd-alone-item-num">
              <NumberScroll :value="item.num"></NumberScroll>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">我的店铺</div>
        <div class="card-hd-right">
          <Refresh @refreshCall="refreshHandle(2)"></Refresh>
        </div>
      </div>
      <div class="card-bd">
        <div
          class="card-bd-item pinduoduo"
          v-for="(item, index) in storeList" :key="index"
          :class="[
            { 'doudian': item.platform === 2 }
          ]"
          @click="goRouter('storeDetail', item)"
        >
          <div class="card-bd-item-top">
            <div class="card-bd-item-name">
              <div class="img-logo">
                <img :src="item.logo" />
              </div>
              <span :title="item.mall_name">{{ item.mall_name }}</span>
            </div>
            <el-tag class="merchant-type" type="primary">
              {{ item.merchant_type_name }}
            </el-tag>
          </div>
          <div class="card-bd-item-bottom">
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">实际销售量</div>
              <div class="card-bd-item-bottom__num">{{ item.actual_sales_volume }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">销售订单数</div>
              <div class="card-bd-item-bottom__num">{{ item.orders_count }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">退款订单数</div>
              <div class="card-bd-item-bottom__num">{{ item.refund_orders_count }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">退款金额</div>
              <div class="card-bd-item-bottom__num">{{ item.refund_sales_volume }}</div>
            </div>
            <div class="card-bd-item-bottom__item">
              <div class="card-bd-item-bottom__title">销售金额</div>
              <div class="card-bd-item-bottom__num">{{ item.sales_volume }}</div>
            </div>
          </div>
        </div>
        <div class="card-bd-item add-item" @click="addPlatformHandle">
          <div class="card-bd-item-title">添加店铺</div>
          <div class="card-bd-item-icon">+</div>
        </div>
      </div>
    </div>

    <global-dialog
      :type="baseData.dialogType"
      :isShow="baseData.showDialog"
      @close="closeGlobalDialog"
    ></global-dialog>
  </div>
</template>

<script setup>
// eslint-disable-next-line
import GlobalDialog from '@/components/GlobalDialog/index.vue'
import { postAccessToken } from '@/api/authorize'
import { getStoreInfo, getMallPageList } from '@/api/stores'
import { getUserInfo } from '@/api/user'
import NumberScroll from '@/components/NumberScroll/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { merchantTypeTag } from '@/utils/tags'
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const baseData = reactive({
  dialogType: 'platform',
  showDialog: false
})
// 店铺数据 总 单独卡片
const aloneList = ref([
  { title: '销售金额', num: 10000, desc: '销售金额' },
  { title: '销售订单数', num: 908, desc: '销售订单数' },
  { title: '退款金额', num: 222, desc: '退款金额' },
  { title: '退款订单数', num: 232, desc: '退款订单数' }
])
// 我的店铺卡片
const storeList = ref([])

// 添加店铺事件
const addPlatformHandle = () => {
  baseData.dialogType = 'platform'
  baseData.showDialog = true
}
// 关闭globalDialog组件
const closeGlobalDialog = () => {
  baseData.showDialog = false
}

// 刷新事件
const refreshHandle = (val) => {
  console.log('refreshHandle', val)
  aloneList.value.map(item => {
    item.num += item.num
    console.log(item)
    return item
  })
}

// 提交token
const postAccessTokenHandle = async (params) => {
  const resToken = await postAccessToken(params)
  const resStoreInfo = await getStoreInfo({
    provider: 1
  })

  // console.log(store.getters.getAuthList)
  // store.commit('stores/setStoresInfo', {
  //   id: 111,
  //   token: '224'
  // })
  // store.dispatch('stores/getStoresInfo')
  console.log(resStoreInfo, '获取商铺信息，存储商铺信息相关')
  // console.log('查询店铺是否签署多多进宝协议接口isDDJB 相关，携参access_token')
  // console.log('storage存储相关，vuex or localStorage')
  if (resToken && resStoreInfo) {
    location.replace('/#home')
    return
  }
  postAccessTokenHandle(params)
}

// goRouter
const goRouter = async (type, params) => {
  switch (type) {
    case 'storeDetail':
      // 将当前店铺信息存入localStorage，并跳转到详情页面
      await store.commit('stores/setStoreInfo', params)
      router.push({
        name: 'storeDetail',
        params: {
          id: params.id
        }
      })
      break
  }
}

onMounted(async () => {
  function getParams(url) {
    const urlObject = new URL(url)
    const params = {}
    for (const [key, value] of urlObject.searchParams.entries()) {
      params[key] = value
    }
    return params
  }
  const urlCheck = getParams(window.location.href)
  if ((Object.keys(urlCheck).length > 0) && urlCheck?.code && urlCheck?.state) {
    const { code, state } = urlCheck
    postAccessTokenHandle({
      code,
      state,
      provider: 1
    })
  } else {
    // 获取当前用户信息
    getUserInfo().then(res => {
      console.log('getUserInfo', res)
      // store.commit('user/setUserInfo', res)
    })
    // 获取当前店铺详情信息
    const getMallDateRes = await getMallPageList({
      page: 1,
      page_size: 7,
      mall_name: ''
    })
    if (getMallDateRes?.data?.length > 0) {
      const resData = getMallDateRes.data
      storeList.value = resData
      for (const i in resData) {
        resData[i].merchant_type_name = merchantTypeTag(1, resData[i].merchant_type)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';

.home {
  height: 100%;
  margin: 10px;
}

.card-box {
  .card-bd {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &-item {
      width: calc( 25% - 15px);
      margin: 0 $mainPX12 $mainPX12 0;
      border-radius: $mainPX12;
      padding: $mainPX12;
      background: $mainWhiteColorFulLight;
      cursor: pointer;
      box-shadow:  0px 0px 5px #ffffff;
      transition: all 0.3s;
      &:hover {
        box-shadow:  0px 0px 9px #6F61E630;
      }
      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        color: $mainColor;
        font-weight: bold;
      }
      &-name {
        display: flex;
        align-items: center;
        justify-self: flex-start;
        .img-logo {
          position: relative;
          border-radius: 6px;
          margin-right: 8px;
          overflow: hidden;
          min-width: 50px;
          img {
            width: 50px;
            height: 50px;
          }
        }
        span {
          @include textlimit2;
        }
      }
      .merchant-type {
        margin-left: 8px;
      }
      &-platform {
        margin-right: 8px;
      }
      &-bottom {
        display: flex;
        flex-wrap: wrap;
        color: $mainGreyLight;
        &__item {
          width: 50%;
          font-size: 14px;
          margin-top: 4px;
        }
        &__title {
          font-size: 12px;
        }
        &__num {
          margin-top: 8px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      &.pinduoduo {
        position: relative;
        overflow: hidden;
        &::after {
          content: "拼多多";
          position: absolute;
          font-size: 30px;
          right: 12px;
          top: 6px;
          font-weight: bold;
          color: #a9beff1f;
          letter-spacing: 1px;
        }
      }
      &.doudian {
        position: relative;
        overflow: hidden;
        &::after {
          content: "抖店";
          position: absolute;
          font-size: 30px;
          right: 12px;
          top: 6px;
          font-weight: bold;
          color: #a9beff1f;
          letter-spacing: 1px;
        }
      }
      &.add-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .card-bd-item {
          width: calc(100% - $mainPX24);
          &-title {
            height: auto !important;
            color: $mainColor;
            margin: 12px 0 8px 0;
          }
          &-icon {
            color: $mainColor;
          }
        }
      }
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(9),
      &:nth-child(13),
      &:nth-child(17),
      &:nth-child(21),
      &:nth-child(25),
      &:nth-child(29),
      &:nth-child(33),
      &:nth-child(37) {
        margin-left: 12px;
      }
    }
  }
}
</style>
