<template>
    <div class="globaldialog">
        <el-dialog
            v-model="centerDialogVisible"
            top="20%"
            align-center
            @close="$emit('close')"
        >
            <div class="globaldialog-alert" v-if="props.type === 'base'">
                <div class="globaldialog-alert-hd">
                    <p>只要4步完成基础设置</p>
                </div>
                <div class="globaldialog-alert-bd">
                    <el-steps :active="4" align-center>
                        <el-step
                            v-for="(item, index) in baseProgressList"
                            :key="index"
                            :title="item.title"
                            >
                            <template v-slot:description>
                                <div class="step-item">
                                    <div class="step-item-num">{{ item.num }}</div>
                                    <div>
                                        <el-button type="primary" round>{{item.sub}}</el-button>
                                    </div>
                                    <div class="step-item-desc">{{ item.desc }}</div>
                                </div>
                            </template>
                        </el-step>
                    </el-steps>
                </div>
            </div>

            <div class="globaldialog-alert" v-if="props.type === 'platform'">
                <div class="globaldialog-alert-hd">
                    <p>请选择授权平台</p>
                </div>
                <div class="globaldialog-alert-bd platform-box">
                    <div class="platform-box-explain">请选择店铺所在平台进行平台授权操作</div>
                    <div class="platform-box-list">
                        <div class="platform-item"
                            :class="{'comingsoon': !item.status}"
                            v-for="(item, index) in platformList" :key="index"
                            @click="changePlatformHandle(item)"
                        >
                            <img :src="item.logo" />
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        </div>
</template>

<script setup>
import { ref, defineProps, watch, reactive } from 'vue'
import { getCodeUrl } from '@/api/authorize'
import pddLogo from '../../icons/img/pdd.png'
// import doudianLogo from '../../icons/img/doudian.png'
// import taobaoLogo from '../../icons/img/taobao.png'
// import jdLogo from '../../icons/img/jd.png'

const props = defineProps({
  type: String, // base、platform
  isShow: Boolean
})
const centerDialogVisible = ref(false)
const baseProgressList = ref([
  { title: '授权店铺', num: '第一步', sub: '去授权店铺', desc: '授权后可同步店铺数据', icon: 'icon' },
  { title: '设置电子面单', num: '第二步', sub: '去设置面单', desc: '设置打印快递单', icon: 'icon' },
  { title: '设置发货地址', num: '第三步', sub: '去设置地址', desc: '设置店铺寄件地址', icon: 'icon' },
  { title: '安装运行打印助手', num: '第四步', sub: '去安装助手', desc: '安装科酷驴report打印助手', icon: 'icon' }
])
const platformList = ref([
  { id: 1, title: '拼多多', logo: pddLogo, status: true }
//   { id: 2, title: '抖店', logo: doudianLogo, status: false },
//   { id: 3, title: '淘宝', logo: taobaoLogo, status: false },
//   { id: 4, title: '京东', logo: jdLogo, status: false }
])
const baseData = reactive({
  url: ''
})

watch(() => props.isShow, (newVal) => {
  centerDialogVisible.value = newVal
})

// 选择平台事件
const changePlatformHandle = async (item) => {
  if (!item.status) return
  baseData.url = await getCodeUrl()
  console.log(baseData.url, 'Comedy')
  baseData.url?.url && window.open(baseData.url?.url, '__blank')
}
</script>

<style lang="scss">
@import '~@/styles/variables.scss';

.globaldialog {
    .el-dialog {
        background-image: $mainWhiteColorFul;
        border-radius: 12px;
        width: 1080px;
    }
    .el-dialog__body {
        padding-top: 0;
    }
    .el-step {
        &__line {
            height: 16px !important;
            top: 24px !important;
            background: $mainColorOpacity25;
        }
        &__icon {
            width: 60px !important;
            height: 60px !important;
        }
        &__title {
            margin: 12px 0;
            font-weight: bold;
        }
        .el-button {
            margin-bottom: 12px;
        }
    }
    .step-item {
        display: flex;
        flex-direction: column;
        position: relative;
        &-num {
            position: absolute;
            top: -152px;
            margin: auto;
            left: 0;
            right: 0;
            font-size: 16px;
            color: $mainGrey;
        }
        &-desc {
            color: $mainGrey;
        }
    }

    .platform-box {
        display: flex;
        flex-direction: column;
        padding: 24px 12px 24px;
        &-explain {
            margin: 0 0 $mainPX24 $mainPX12;
            color: $mainGrey;
        }
        &-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .platform-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 245px;
                height: 80px;
                transition: all 0.3s;
                cursor: pointer;
                overflow: hidden;
                border-radius: $mainPX12;
                margin-bottom: $mainPX12;
                margin-left: $mainPX12;
                &:nth-child(1),
                &:nth-child(5) {
                    margin-left: 0;
                }
                &:hover {
                    box-shadow:  0px 0px 9px #6F61E630;
                }
                &.comingsoon {
                    cursor: not-allowed;
                    position: relative;
                    transition: all 0.3s;
                    &::after {
                        content: "平台建设中...";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $mainWhite;
                        background: $mainBlackLight;
                    }
                }
                img {
                    width: 161px;
                    height: 56px;
                }
            }
        }
    }

    &-alert {
        &-hd {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $mainColor;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 24px;
        }
        &-bd {
            background: $mainColorOpacity10;
            border-radius: 12px;
            padding: 64px 12px 24px;
        }
    }
}
</style>
